import styled, { css } from 'styled-components';
import { BRAND, GREYS, FUNCTIONAL, FONT } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'CORE__UI/globals/core__breakpoints.js';
import theme from 'UI/globals/theme';

const buttonSizer = (size, labelPos) => {
  if (labelPos && labelPos === 'up') {
    return '48px';
  } else {
    switch (size) {
      case 'small':
        return '32px';
      case 'default':
        return '48px';
      default:
        return '48px';
    }
  }
};

export const SOB = styled.button`
  align-self: start;
  border: 0;
  border-radius: 4px;
  flex: 1;
  line-height: 1;
  margin-right: 8px;
  margin-bottom: 4px;
  font-family: ${theme.fontFamily};
  font-size: 12px;
  line-height: 16px;
  ${({ priceBoosts }) =>
    priceBoosts &&
    css`
      margin-bottom: 0;
    `}
  height: ${({ size, labelPos }) => buttonSizer(size, labelPos)};
  @media screen and (max-width: ${BREAKPOINT_S}) {
    height: 48px !important;
    min-height: 48px;
  }
  position: relative;
  background-size: 14px 18px;
  background-position: 90% 50%;
  background-repeat: no-repeat;
  background-color: ${({ inactive }) =>
    inactive ? GREYS.doveL : BRAND.tertiaryL};
  cursor: ${({ inactive }) => (inactive ? 'not-allowed' : 'pointer')};
  font-weight: ${({ inactive }) => (inactive ? 'normal' : 900)};
  color: ${({ inactive }) => (inactive ? GREYS.silver : BRAND.primary)};
  box-shadow: ${({ selected }) =>
    selected ? `0 0 0 1px ${BRAND.primary}` : `none`};
  ${({ inactive }) =>
    !inactive &&
    css`
      @media (hover) {
        &:hover {
          box-shadow: 0 0 0 1px
            ${({ selected }) => (selected ? BRAND.primaryL : BRAND.tertiary)};
        }
      }
    `}
  ${({ inactive }) =>
    inactive &&
    css`
      background-image: url('//assets.fsbtech.com/react/icons/common/lock-light.svg');
      background-position: center;
      span {
        display: none;
      }
    `}
&:focus {
    outline: none;
  }
  &:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: ${BREAKPOINT_S}) {
    min-width: 'unset';
  }
  height: 48px;
  width: 100%;

  ${({ theme }) =>
    theme.dark &&
    css`
      background-color: ${GREYS.silver};
      color: ${BRAND.secondary};
      ${({ inactive }) =>
        !inactive &&
        css`
          &:hover {
            box-shadow: 0 0 0 1px
              ${({ selected }) => (selected ? BRAND.primaryL : BRAND.tertiary)};
          }
        `}
      ${({ inactive }) =>
        inactive &&
        css`
          background-image: url('//assets.fsbtech.com/react/icons/common/lock-dark.svg');
          background-color: ${GREYS.silverD};
          border: 1px solid ${FONT.light};
          color: ${GREYS.silver};
          background-position: center;
          span {
            display: none;
          }
        `}
    `}
`;

export const NAB = styled(SOB)`
  border-radius: 4px;
  background-color: ${GREYS.doveL};
  color: ${GREYS.doveL};
  user-select: none;
  min-width: 3em;
  font-size: 12px;
  line-height: 16px;
  flex: ${({ americanLayout }) => (americanLayout ? 'unset' : 1)};
  height: ${({ americanLayout }) => (americanLayout ? `32px` : `48px`)};
  cursor: not-allowed;
  &:hover {
    box-shadow: none;
  }
  @media screen and (max-width: ${BREAKPOINT_S}) {
    height: 48px;
  }
  ${({ theme }) =>
    theme.dark &&
    css`
      background-color: ${GREYS.silverD};
      color: ${GREYS.silverD};
    `}
`;

export const SS = styled.span`
  position: absolute;
  width: 0;
  height: 0;
`;

export const OD = styled(SS)`
  left: 4px;
  bottom: 4px;
  border-bottom: 8px solid ${FUNCTIONAL.negative};
  border-right: 8px solid transparent;
`;

export const OU = styled(SS)`
  right: 4px;
  top: 4px;
  border-top: 8px solid ${FUNCTIONAL.positive};
  border-left: 8px solid transparent;
`;

export const CS = styled.span`
  display: flex;
  width: 90%;
  flex-direction: ${({ labelPos }) => (labelPos === 'up' ? 'column' : 'row')};
  padding: 0;
  justify-content: center;
  @media screen and (max-width: ${BREAKPOINT_S}) {
    flex-direction: column;
  }
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
`;

export const CSCnt = styled(CS)`
  justify-content: center;
`;

export const LS = styled.span`
  font-size: 12px;
  color: ${FONT.dark};
  font-weight: 400;
  padding-right: ${({ labelPos }) => (labelPos === 'up' ? '0' : '12px')};
  @media screen and (max-width: ${BREAKPOINT_S}) {
    padding-right: 0;
  }
  ${({ theme }) =>
    theme.dark &&
    css`
      color: ${GREYS.doveD};
    `}
`;
