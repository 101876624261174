import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import replaceAnd from 'Services/translations/core__replaceAnd';
import { League } from 'UI/apps/EventsApp/EventsTableBodySection';
import {
  MarketRefLabel_EX,
  EwTerms,
} from 'CORE__UI/apps/EventsOutrightsApp/core__eventsOutrightsTableBodySection';
import { StyledOddsButton } from 'UI/apps/EventsApp/Market';
import { parseDecimalPlaces } from 'Services/global/core__odds-format';
import { ViewMoreLabel } from 'UI/apps/EventsApp/EventsTableBody';
import {
  placeBet,
  sortOutrights,
} from '../../../../../core__eventsOutrights-utils';
import PubSub from 'Services/pubsub/core__pubsub';
import { Constants } from '../../../../../core__eventsOutrights-constants';
import {
  ButtonDiv,
  TeamName,
  Team,
  EventsRow,
  ModifiedViewMoreBar,
} from 'UI/apps/EventsOutrightsApp/EventsOutrightsTableBodySection';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { Translations } from '../../../../../core__eventsOutrights-app';
import { Kickoff } from '../../../Kickoff/core__kickoff';
import EA from 'UI/globals/ExpandArrow';
import { getOdds } from 'Services/global/core__american-format';
import PROJECT from '../../../../../../../../project';

/**
 * this component contains market header and rows of table for each subcategory
 * @param {Object} market object of subcategory contains events array and subcategory details `market`
 * @param {Object} category object of category `category`
 */
export const EventsOutrightsTableBodySection = ({
  market,
  oddsFormat,
  selectionIds,
  sortBy,
  sortDir,
  collapse,
  showEWTermsAtBottom,
}) => {
  const [collapsed, setCollapsed] = useState(collapse);
  const [viewLessButton, setViewLessButton] = useState(false);
  const [viewAll, setViewAll] = useState();
  const toggleData = () => {
    setCollapsed(!collapsed);
  };

  const [outrightsArray, setOutrightsArray] = useState(
    sortBy && market?.selection
      ? sortOutrights(sortBy, sortDir, market.selection)
      : market?.selection
  );

  useEffect(() => {
    if (outrightsArray?.length <= Constants.maxRowsToShow) {
      return setViewAll(true);
    }
  }, [outrightsArray]);

  //web socket
  useEffect(() => {
    PubSub.emit('ws:sub:market', [market.id]);
    const marketsPuSub = PubSub.listen('ws:market', wsData => {
      if (wsData.id === market.id) {
        if (wsData.updatedSelections) {
          const selectionsToUpdate = wsData.selections.reduce(
            (acc, selection) => {
              acc[selection.id] = { ...selection };
              return acc;
            },
            {}
          );

          const updatedOutrightsArray = market.selection.map(selection => {
            const selectionId = selection?.id;
            if (selectionsToUpdate[selectionId]) {
              const updatedSelection = selectionsToUpdate[selectionId];
              if (updatedSelection.price.id) {
                //price is changed in new object

                const oddsTrend =
                  selection.price[0].decimal > updatedSelection.price.decimal
                    ? Constants.ODDS_DECREASE
                    : Constants.ODDS_INCREASE;
                selection.price = [updatedSelection.price];
                selection.oddsTrend = oddsTrend;
              }
              updatedSelection.price = selection.price; //will work even when price not changed

              selection = {
                ...selection,
                ...updatedSelection,
                name: selection.name, //name is lost when price change
              };
            }
            return selection;
          });
          setOutrightsArray(
            sortOutrights(sortBy, sortDir, updatedOutrightsArray)
          );
        }
      }
    });
    return () => {
      marketsPuSub?.unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let outrightRows = outrightsArray;

  //for handling view all logic
  if (outrightsArray?.length > Constants.maxRowsToShow) {
    outrightRows = outrightsArray.slice(0, Constants.maxRowsToShow);

    if (viewAll) {
      outrightRows = outrightsArray;
    }
  }
  // EW rules check
  const places = { 1: 'Winner Only', 2: '1-2', 3: '1-2-3' };
  const fractions = { 1: 'Full Price' };
  let getPlaces;
  let getFractions;
  let placeTerms;
  if (Array.isArray(market.book)) {
    for (const book of market.book) {
      if (book.placeTerms) {
        getPlaces =
          book.places > 3 ? '1 - ' + book.places : places[book.places];
        getFractions =
          book.fraction > 1
            ? '1/' + book.fraction + ' odds'
            : fractions[book.fraction];
        placeTerms = getPlaces + ' at ' + getFractions;
        market['placeTerms'] = placeTerms;
      }
    }
  }

  const formatOdds = ({ fractional, decimal }) => {
    switch (oddsFormat) {
      case GLOBAL_CONSTANTS.FRACTIONAL:
        return fractional;

      case 'american':
        return getOdds(decimal, true);

      default:
      case GLOBAL_CONSTANTS.DECIMAL:
        return parseDecimalPlaces(decimal);
    }
  };

  return (
    <React.Fragment>
      <League onOutrights={true} onClick={toggleData} className="tgl-btn">
        <MarketRefLabel_EX>
          {market.eventName
            ? `${market.eventName} - ${replaceAnd(market.name)}`
            : replaceAnd(market.name)}
        </MarketRefLabel_EX>
        <Kickoff data={market.betUntil} isShow={PROJECT.JpTimeFormat} />
        <EA expanded={!collapsed} />
      </League>
      {!showEWTermsAtBottom && !collapsed && market['placeTerms'] && (
        <League isEW={true}>
          {<EwTerms> EW {market['placeTerms']}</EwTerms>}
        </League>
      )}

      {outrightRows?.map(team => {
        let active = false;
        if (market.active) {
          active = team.active; //selection
        } else {
          active = false;
        }

        return (
          <React.Fragment key={team.id}>
            {!collapsed && team.price && (
              <EventsRow>
                <Team>
                  <TeamName>{team.name}</TeamName>
                </Team>
                <ButtonDiv>
                  <StyledOddsButton
                    odds={formatOdds(team.price[0])}
                    oddsTrend={team.oddsTrend}
                    onClick={() => placeBet(team?.id)}
                    selected={selectionIds?.indexOf(team?.id) !== -1}
                    inactive={!active}
                  />
                </ButtonDiv>
              </EventsRow>
            )}
          </React.Fragment>
        );
      })}
      {showEWTermsAtBottom && (
        <EventsRow ew={true}>These are EW terms</EventsRow>
      )}
      {!collapsed && !viewAll ? (
        <ModifiedViewMoreBar
          onClick={() => {
            setViewAll(true);
            setViewLessButton(true);
          }}
        >
          <ViewMoreLabel>{Translations.get('markets.viewmore')}</ViewMoreLabel>
        </ModifiedViewMoreBar>
      ) : (
        !collapsed &&
        viewLessButton && (
          <ModifiedViewMoreBar
            onClick={() => {
              setViewAll(false);
              setViewLessButton(false);
            }}
          >
            <ViewMoreLabel>
              {Translations.get('markets.viewless')}
            </ViewMoreLabel>
          </ModifiedViewMoreBar>
        )
      )}
    </React.Fragment>
  );
};

EventsOutrightsTableBodySection.propTypes = {
  market: PropTypes.shape({
    active: PropTypes.any,
    id: PropTypes.any,
    name: PropTypes.string,
    selection: PropTypes.array,
    eventName: PropTypes.string,
    book: PropTypes.array,
    betUntil: PropTypes.number,
    placeTerms: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }),
  oddsFormat: PropTypes.string,
  selectionIds: PropTypes.array,
  sortBy: PropTypes.string,
  sortDir: PropTypes.string,
  collapse: PropTypes.bool,
  showEWTermsAtBottom: PropTypes.bool,
};
