import styled from 'styled-components';
import { BRAND, GREYS } from 'UI/globals/colours';

export const ViewMoreBar = styled.div`
  border-bottom: 1px solid ${BRAND.tertiary};
  border-top: 1px solid ${BRAND.tertiary};
  padding: 1em;
  color: ${GREYS.silver};
  font-size: 12px;
  cursor: pointer;
  font-weight: 800;
  display: flex;
`;
export const ViewMoreLabel = styled.p`
  flex: 1;
  text-align: center;
`;
