import {
  ButtonDiv,
  TeamName,
  Team,
  EventsRow,
  ModifiedViewMoreBar,
} from 'CORE__UI/apps/EventsOutrightsApp/core__eventsOutrightsTableBodySection';
import styled from 'styled-components';
import { GREYS, BRAND } from '../../globals/colours';
import { BREAKPOINT_S } from '../../globals/breakpoints';
const ButtonDiv_GDWN = styled(ButtonDiv)`
  @media (max-width: ${BREAKPOINT_S}) {
    margin-right: 8px;
  }
`;
const TeamName_GDWN = styled(TeamName)`
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 13px;
  }
`;
const Team_GDWN = styled(Team)`
  @media (max-width: ${BREAKPOINT_S}) {
    margin-top: 21px;
    margin-bottom: 21px;
    margin-left: 16px;
  }
`;
const EventsRow_GDWN = styled(EventsRow)`
  background-color: ${GREYS.silverD};
  color: ${GREYS.white};
  border-color: ${BRAND.primary};
`;
const ModifiedViewMoreBar_GDWN = styled(ModifiedViewMoreBar)`
  background-color: ${GREYS.silverD};
  color: ${GREYS.white};
`;
export {
  ButtonDiv_GDWN as ButtonDiv,
  TeamName_GDWN as TeamName,
  Team_GDWN as Team,
  EventsRow_GDWN as EventsRow,
  ModifiedViewMoreBar_GDWN as ModifiedViewMoreBar,
};
