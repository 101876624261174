import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import getComposeEnhancers from 'Services/redux/core__redux-dev-tools';
import { getRequest } from 'Services/http/core__axios';
import { outrightsData } from './core__eventsOutrights-actions';
import { dataReducer } from './core__eventsOutrights-reducer';
import {
  getCatSubcatFromUrl,
  parseAppConfig,
} from './core__eventsOutrights-utils';

export function getOutrightsUrl(appConfig) {
  let {
    catreftoload,
    subcatreftoload,
    requestURL,
    subcatalbumref,
    permissivealbum,
    marketalbumref,
    subcategoryRef,
    subcatidtoload,
  } = parseAppConfig(appConfig);
  if (requestURL && (!catreftoload || !subcatreftoload)) {
    const catSubcat = getCatSubcatFromUrl(requestURL, appConfig?.urlamendments);
    if (!catreftoload) catreftoload = catSubcat.catreftoload;
    if (!subcatreftoload)
      subcatreftoload = subcategoryRef ?? catSubcat.subcatreftoload;
    if (!subcatidtoload && !isNaN(catSubcat.subcatidtoload))
      subcatidtoload = catSubcat.subcatidtoload;
  }
  if (requestURL && !subcatidtoload) {
    const catSubcat = getCatSubcatFromUrl(requestURL, appConfig?.urlamendments);
    if (!subcatidtoload && !isNaN(catSubcat.subcatidtoload))
      subcatidtoload = catSubcat.subcatidtoload;
  }
  let albumStrings = '';
  if (subcatalbumref) {
    albumStrings += `&subcatAlbumRef=${subcatalbumref}`;
  }
  if (marketalbumref) {
    albumStrings += `&marketAlbumRef=${marketalbumref}`;
  }
  if (permissivealbum && (subcatalbumref || marketalbumref)) {
    albumStrings += `&permissiveAlbum=${permissivealbum}`;
  }
  if (catreftoload && subcatidtoload) {
    let url = '/fsb-api-rest/bet/category';
    url += `/${catreftoload}/id/${subcatidtoload}.fsb?detail=OUT${albumStrings}`;
    return url;
  }
  if (catreftoload && subcatreftoload) {
    let url = '/fsb-api-rest/bet/category';
    url += `/${catreftoload}/${subcatreftoload}.fsb?detail=OUT${albumStrings}`;
    return url;
  }
  if (catreftoload) {
    let url = '/fsb-api-rest/bet/category';
    url += `/${catreftoload}.fsb?detail=OUT${albumStrings}`;
    return url;
  }
}

export const reducer = combineReducers({
  data: dataReducer,
});

const sortByMarketAlbumRef = response => {
  if (!response.marketOrder) return;

  const markets = [];
  response.category?.forEach(c => {
    c.subcat?.forEach(sc => {
      sc.event?.forEach(e => {
        e.market?.forEach(m => {
          m.name = `${e.name} - ${m.name}`;
          markets.push(m);
        });
      });
    });
  });

  if (!markets) return;

  const sortingArr = response.marketOrder.split(',');
  markets.sort((a, b) => {
    if (!sortingArr.includes(a.id.toString())) return 1;
    if (!sortingArr.includes(b.id.toString())) return -1;
    return (
      sortingArr.indexOf(a.id.toString()) - sortingArr.indexOf(b.id.toString())
    );
  });

  const event = response.category[0].subcat[0].event[0];
  const subcat = response.category[0].subcat[0];
  event.name = '';
  subcat.name = '';
  const cat = response.category[0];

  event.market = markets;
  subcat.event = [event];
  cat.subcat = [subcat];

  response.category = [cat];
};

/**
 * this is a thunk middleware function, for initial api call and dispatch to store
 * @param {Array | String} url, array if multiple requests are to be made
 * @param {Request} nodeRequest null or node api from ssr
 * @param {Object} appConfig event app parameters
 */
export const fetchData = (url, nodeRequest, appConfig) => dispatch => {
  if (!url) {
    return Promise.resolve();
  }
  const requester = !nodeRequest ? getRequest : nodeRequest;
  return requester(url).then(res => {
    const response = res;

    if (appConfig.marketalbumref !== '' && appConfig.usealbummarketorder) {
      sortByMarketAlbumRef(response);
      delete response.marketOrder;
    }
    dispatch(outrightsData({ ...response, appConfig }));
  });
};

const composeEnhancers = getComposeEnhancers({
  name: 'events-outrights-store',
});

export default initialState =>
  createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
