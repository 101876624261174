import PubSub from 'Services/pubsub/core__pubsub';
import { Translations } from './core__eventsOutrights-app';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import moment from 'moment';
import PROJECT from '../../../project';
import { getCookie } from 'Services/cookie/core__cookies';
import { Constants } from './core__eventsOutrights-constants';
/**
 * to parse app config and get different parameters from it
 * @param {Object} appConfig app parameters
 */
export const parseAppConfig = appConfig => {
  //category ref to load, required for sequencing
  const catreftoload =
    (appConfig.catreftoload && appConfig.catreftoload.split(',')) || null;
  //request url
  const requestURL = appConfig?.requestURL;
  return {
    catreftoload,
    requestURL,
    marketalbumref: appConfig?.marketalbumref,
    subcatalbumref: appConfig?.subcatalbumref,
    permissivealbum: appConfig?.permissivealbum,
    subcatreftoload: appConfig?.subcatreftoload,
    subcategoryRef: appConfig?.subcategoryRef,
  };
};
/**
 * returns category and subcategory from wildcard in url
 * @param {String} requestURL
 */
export const getCatSubcatFromUrl = (requestURL, urlAmendments) => {
  const pathParams = requestURL.split('/');
  let catreftoload = null,
    subcatreftoload = null,
    subcatidtoload = null;
  if (pathParams[2] && pathParams[2].trim() !== '') {
    catreftoload = pathParams[2];
  }
  if (pathParams[3] && pathParams[3].trim() !== '') {
    subcatreftoload = pathParams[3];
    if (urlAmendments) {
      [subcatreftoload, subcatidtoload] = subcatreftoload.split(/-\s*([^-]+)$/);
    }
  }
  return {
    catreftoload: urlAmendments
      ? catreftoload?.toUpperCase().replace(/-/g, '_')
      : catreftoload,
    subcatreftoload: urlAmendments
      ? subcatreftoload?.toUpperCase().replace(/-/g, '_')
      : subcatreftoload,
    subcatidtoload,
  };
};

export const placeBet = selectionId => {
  emitSelectionIdForBetSlip(selectionId);
};
/**
 * function to emit pubsub to generate betslip
 * @param {String} selectionId
 */
const emitSelectionIdForBetSlip = selectionId => {
  PubSub.emit(PubsubEvents.BetslipUpdate, { selectionId });
};

export const sortOutrights = (sortBy, sortDir, market) => {
  const extraMarkets = [];
  market = market.filter(mkt => {
    if (mkt.price && mkt.active) {
      return true;
    } else {
      // extraMarkets.push(mkt);  Do not remove
      return false;
    }
  });
  if (sortBy === 'name') {
    if (sortDir === 'DESC') {
      market.sort((a, b) => (a.name > b.name && -1) || 1);
    } else {
      market.sort((a, b) => (a.name > b.name && 1) || -1);
    }
  } else if (sortBy === 'price') {
    if (sortDir === 'DESC') {
      market.sort((a, b) => {
        if (a.price && b.price) {
          if (
            parseFloat(a.price[0]?.decimal) !== parseFloat(b.price[0]?.decimal)
          ) {
            return (
              parseFloat(b.price[0]?.decimal) - parseFloat(a.price[0]?.decimal)
            );
          } else {
            return (b.name > a.name && 1) || -1;
          }
        }
      });
    } else {
      market.sort((a, b) => {
        if (a.price && b.price) {
          if (
            parseFloat(a.price[0]?.decimal) !== parseFloat(b.price[0]?.decimal)
          ) {
            return (
              parseFloat(a.price[0]?.decimal) - parseFloat(b.price[0]?.decimal)
            );
          } else {
            return (a.name > b.name && 1) || -1;
          }
        }
      });
    }
  }
  market = market.concat(extraMarkets);
  return market;
};

export const dateOrdinal = d =>
  d +
  (31 === d || 21 === d || 1 === d
    ? Translations.get('number.st')
    : 22 === d || 2 === d
    ? Translations.get('number.nd')
    : 23 === d || 3 === d
    ? Translations.get('number.rd')
    : Translations.get('number.th'));

export const getDate = scheduledStart => {
  const localeKey = getCookie('localeKey') === Constants.JAPANESE;
  const date = moment(scheduledStart);
  const day = dateOrdinal(date.date());
  const hour = date.format('HH');
  const minutes = date.format('mm');
  const month = Translations.get(`month.${date.format('MMM')}`);
  const year = `${
    localeKey
      ? ` ${date.format('YYYY')}${Translations.get('number.year')}`
      : `,${date.format('YYYY')}`
  }`;

  const time = `${hour}:${minutes} `;
  if (PROJECT.JpTimeFormat) {
    return `${month} ${day}${year} ${time}`;
  } else {
    return `${day} ${month}${year} ${time}`;
  }
};
