import styled, { css } from 'styled-components';
import { BRAND, GREYS, FONT } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

export const League = styled.div`
  border-bottom: ${({ theme }) =>
    theme.dark ? `1px solid ${GREYS.silver}` : `1px solid ${BRAND.tertiary}`};
  border-top: ${({ theme }) =>
    theme.dark ? `1px solid ${GREYS.silver}` : `1px solid ${BRAND.tertiary}`};
  background-color: ${({ theme }) =>
    theme.dark ? GREYS.silverD : GREYS.white};

  padding: 1em;
  color: ${({ theme }) => (theme.dark ? FONT.lightMute : GREYS.silver)};
  font-size: 12px;
  cursor: pointer;
  font-weight: 800;
  display: flex;
  position: relative;
  span {
    position: absolute;
    right: 16px;
  }
  ${({ onOutrights }) =>
    onOutrights &&
    css`
      padding-right: 32px;
      &:not(:first-of-type) {
        margin-top: 8px;
      }
    `}
`;

export const MarketRefLabel = styled.div`
  align-items: center;
  flex: 3;
  @media (max-width: ${BREAKPOINT_S}) {
    flex: 2;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const MarketWagerLabel = styled(MarketRefLabel)`
  justify-content: center;
  font-weight: normal;
  text-transform: capitalize;
  text-align: center;
`;

export const MarketRefLabelContainer = styled.div`
  display: flex;
  flex: ${props => (!props.americanLayout ? '2' : '1')};
  @media (max-width: ${BREAKPOINT_S}) {
    flex: ${props => (!props.americanLayout ? '1.5' : '0.5')};
  }
  align-items: center;
  border-right: ${({ onVirtuals }) =>
    !onVirtuals && `1px solid ${BRAND.tertiary}`};
  &:last-child {
    border-right: none;
  }
  margin-right: 1px;
  padding-right: 2px;
`;

export const EventMarketEmpty = styled.div`
  width: 54px;
  flex: 0.12;
  margin-left: 6px;
  ${({ americanLayout }) =>
    americanLayout &&
    css`
      margin-left: 6px;
      flex: 0.07;
    `}
`;
