import styled, { css } from 'styled-components';
import { BRAND } from 'UI/globals/colours';
import OddsButton from 'UI/buttons/OddsButton';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

export const EventsCell = styled.div`
  display: flex;
  padding-right: ${props => (props.americanLayout ? `2px` : `0`)};
  border-right: ${({ onVirtuals }) => {
    !onVirtuals && `1px solid ${BRAND.tertiary}`;
  }};
  margin-right: ${props => (props.americanLayout ? `2px` : `0.5em`)};
  &:last-child {
    padding-right: 0;
    border-right: none;
    margin-right: 0;
  }
  flex-direction: ${props => (props.americanLayout ? 'column' : 'initial')};
  ${({ reverseMarket, americanLayout }) =>
    reverseMarket &&
    americanLayout &&
    css`
      flex-direction: column-reverse;
    `}
  flex: ${props => (!props.americanLayout ? '2' : '1')};
  @media (max-width: ${BREAKPOINT_S}) {
    flex: ${props => (!props.americanLayout ? '1.5' : '0.5')};
  }
`;

export const StyledOddsButton = styled(OddsButton)`
  align-self: ${props => (props.americanLayout ? 'unset' : 'start')};
  flex: ${props => (props.americanLayout ? 'unset' : `1`)};
  margin: ${props => (props.americanLayout ? `0 2px 2px 0` : `0 8px 0 0`)};
`;
