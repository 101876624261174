import {
  EventsCell,
  StyledOddsButton,
} from 'CORE__UI/apps/EventsApp/core__market';
import styled, { css } from 'styled-components';
import { GREYS, FONT, BRAND } from 'UI/globals/colours';
import { BREAKPOINT_S, BREAKPOINT_L } from 'UI/globals/breakpoints';

const EventsCell_GDWN = styled(EventsCell)`
  border: unset;
  margin-right: 4px;
  padding-right: 4px;
`;

const StyledOddsButton_GDWN = styled(StyledOddsButton)`
  background-color: ${GREYS.black};
  height: ${props => (props.americanLayout ? `32px` : `48px`)};
  ${({ odds, inactive }) =>
    !odds || inactive
      ? css`
          background-color: ${GREYS.silverD};
          border: 1px solid ${FONT.primary};
        `
      : css`
          background-color: ${GREYS.black};
        `}

  @media (max-width: ${BREAKPOINT_L}) {
    ${({ inactive, theme }) =>
      !inactive &&
      theme.dark &&
      css`
        &:hover {
          box-shadow: 0 0 0 1px
            ${({ selected }) => (selected ? BRAND.secondary : `transparent`)};
        }
      `}
  }
  @media (max-width: ${BREAKPOINT_S}) {
    height: 46px !important;
  }
`;

export {
  EventsCell_GDWN as EventsCell,
  StyledOddsButton_GDWN as StyledOddsButton,
};
