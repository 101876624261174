import styled from 'styled-components';
import { GREYS, FONT } from '../../globals/colours';
import { BREAKPOINT_S } from '../../globals/breakpoints';
import {
  League,
  MarketRefLabel,
  MarketWagerLabel,
  MarketRefLabelContainer,
  EventMarketEmpty,
} from 'CORE__UI/apps/EventsApp/core__eventsTableBodySection';

const L_EX = styled(League)`
  color: ${GREYS.doveD};
  border-bottom: 1px solid ${FONT.dark};
  border-top: none;
  &:first-child {
    border-top: 1px solid ${FONT.dark};
  }
  background-color: ${GREYS.silverD};
  height: 40px;
  line-height: 16px;
`;

const MRLC_EX = styled(MarketRefLabelContainer)`
  border-color: ${FONT.dark}};
  border-right: 1px solid ${FONT.dark}};
`;

const MarketRefLabel_GDWN = styled(MarketRefLabel)`
  color: ${FONT.secondary};
`;
const MarketWagerLabel_GDWN = styled(MarketWagerLabel)`
  @media screen and (max-width: ${BREAKPOINT_S}) {
    width: 50px;
  }
`;
export {
  L_EX as League,
  MarketRefLabel_GDWN as MarketRefLabel,
  MarketWagerLabel_GDWN as MarketWagerLabel,
  MRLC_EX as MarketRefLabelContainer,
  EventMarketEmpty,
};
