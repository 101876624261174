import styled from 'styled-components';
import { GREYS } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

export const Kicker = styled.div`
  font-size: 12px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  margin: 0;
  text-align: right;
  padding-right: 40px;
  @media (max-width: ${BREAKPOINT_S}) {
    padding-right: 24px;
    padding-left: 4px;
  }
`;
