import React from 'react';
import PropTypes from 'prop-types';
import { EAr } from 'UI/globals/ExpandArrowStyles';

const EA = ({ expanded, active, size, theme, onVirtuals, onClick, kyc }) => {
  return (
    <EAr
      active={active}
      expanded={expanded}
      size={size}
      theme={theme}
      isVirtuals={onVirtuals}
      onClick={onClick}
      kyc={kyc}
    ></EAr>
  );
};

EA.propTypes = {
  active: PropTypes.bool,
  expanded: PropTypes.bool,
  size: PropTypes.number,
  theme: PropTypes.string,
  onVirtuals: PropTypes.bool,
  onClick: PropTypes.func,
  kyc: PropTypes.bool,
};

export default EA;
