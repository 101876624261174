import PropTypes from 'prop-types';
import React from 'react';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { EventsOutrightsTableBodySection } from '../EventsOutrightsTableBodySection';
import { ViewMoreLabel } from 'UI/apps/EventsApp/EventsTableBody';
import { ModifiedViewMoreBar } from 'UI/apps/EventsOutrightsApp/EventsOutrightsTableBodySection';
import { Translations } from '../../../../../core__eventsOutrights-app';
import { useState } from 'react';

/**
 * this component is a wrapper on multiple body section to preprocess grouped subcats data
 * @param {Object} subcatsWithEvents object of multiple subcategory contains events array and subcategory details `subcatsWithEvents`
 * @param {Object} marketTypes array of market types to be displayed in subheader `marketTypes`
 * @param {Object} maxMarketsToShow number of columns to show `maxMarketsToShow`
 * @param {Object} betslipSelections array of selection ids whose betslip is selected `betslipSelections`
 * @param {Object} category object of category `category`
 * @param {Object} selectionsToRender object of selection refs `selectionsToRender`
 */

export const EventsOutrightsTableBody = ({
  oddsFormat,
  outrightsData,
  selectionIds,
  sortBy,
  sortDir,
  collapseAll,
  marketsExpanded,
  defaultMarketsCount,
  appConfig,
}) => {
  let categoryName, subcatName, categoryRef;
  const outrightsMarkets = [];
  const [viewMore, setViewMore] = useState(false);

  if (outrightsData?.category) {
    categoryName = outrightsData.category[0]?.name;
    categoryRef = outrightsData.category[0]?.ref;
    outrightsData?.category[0]?.subcat.forEach(sc => {
      subcatName = sc.name;
      sc.event.forEach(event => {
        event.market.forEach(mrkt => {
          if (event.market.length > 1) {
            mrkt.eventName = event.name;
          }
          outrightsMarkets.push(mrkt);
        });
      });
    });
  }
  if (categoryName && subcatName) {
    const selectedCategory = appConfig?.requestURL.split('/')[2];
    if (
      appConfig.requestURL?.split(selectedCategory).length === 2 &&
      !appConfig?.showcompetitionname
    ) {
      PubSub.emit(PubsubEvents.UpdateCategoryListSelection, {
        category: categoryRef,
        catName: categoryName,
      });
    } else {
      PubSub.emit(PubsubEvents.UpdateCategoryListSelection, {
        category: categoryRef,
        subcat: subcatName,
        catName: categoryName,
      });
    }
  }
  const findCollapse = marketId => {
    return !collapseAll && !marketsExpanded && marketsExpanded !== 0
      ? false
      : collapseAll || marketId >= marketsExpanded;
  };

  let filteredOutrightsMarkets = outrightsMarkets;
  if (outrightsMarkets && !viewMore && defaultMarketsCount > 0) {
    filteredOutrightsMarkets = outrightsMarkets.slice(0, defaultMarketsCount);
  }

  if (outrightsMarkets?.length > 0) {
    return (
      <React.Fragment>
        {filteredOutrightsMarkets?.map((market, marketId) => (
          <EventsOutrightsTableBodySection
            key={marketId}
            market={market}
            oddsFormat={oddsFormat}
            selectionIds={selectionIds}
            sortBy={sortBy}
            sortDir={sortDir}
            collapse={findCollapse(marketId)}
            showEWTermsAtBottom={appConfig.ewtermsatbottom}
          />
        ))}
        {defaultMarketsCount && defaultMarketsCount > 0 && (
          <ModifiedViewMoreBar onClick={() => setViewMore(!viewMore)}>
            <ViewMoreLabel>
              {viewMore
                ? Translations.get('markets.viewless')
                : Translations.get('markets.viewmore')}
            </ViewMoreLabel>
          </ModifiedViewMoreBar>
        )}
      </React.Fragment>
    );
  } else {
    return <React.Fragment />;
  }
};

EventsOutrightsTableBody.propTypes = {
  oddsFormat: PropTypes.string,
  outrightsData: PropTypes.object,
  selectionIds: PropTypes.array,
  noEventData: PropTypes.array,
  sortBy: PropTypes.string,
  sortDir: PropTypes.string,
  collapseAll: PropTypes.bool,
  marketsExpanded: PropTypes.number,
  defaultMarketsCount: PropTypes.number,
  appConfig: PropTypes.object,
};
