import {
  SOB,
  NAB,
  SS,
  OD,
  OU,
  CS,
  CSCnt,
  LS,
} from 'CORE__UI/buttons/OddsButton/core__oddsButtonStyles';
import styled, { css } from 'styled-components';
import { BRAND, GREYS } from '../globals/colours';
import { BREAKPOINT_L } from 'UI/globals/breakpoints';

const SOB_GDWN = styled(SOB)`
  background-color: ${({ inactive }) =>
    inactive ? GREYS.silverD : GREYS.black};
  color: ${BRAND.secondary};
  ${({ inactive }) =>
    !inactive &&
    css`
      box-shadow: 0 0 0 1px
        ${({ selected }) => (selected ? BRAND.secondary : 'transparent')};
      @media (hover) {
        &:hover {
          box-shadow: 0 0 0 1px
            ${({ selected }) => (selected ? BRAND.primaryL : BRAND.primaryL)};
        }
      }
    `}
  @media (max-width: ${BREAKPOINT_L}) {
    ${({ inactive, theme }) =>
      !inactive &&
      theme.dark &&
      css`
        &:hover {
          box-shadow: 0 0 0 1px
            ${({ selected }) => (selected ? BRAND.secondary : `transparent`)};
        }
      `}
  }

  ${({ inactive }) =>
    inactive &&
    css`
      background-color: ${GREYS.silverD};
      border: none;
      color: ${GREYS.dove};
    `}
`;
const NAB_GDWN = styled(NAB)`
  background-color: ${GREYS.silverD};
  color: ${GREYS.silverD};
`;
const LS_GDWN = styled(LS)`
  color: ${({ inactive }) => (inactive ? GREYS.dove : GREYS.white)};
`;
export {
  SOB_GDWN as SOB,
  NAB_GDWN as NAB,
  SS,
  OD,
  OU,
  CS,
  CSCnt,
  LS_GDWN as LS,
};
