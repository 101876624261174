import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { connect } from 'react-redux';
import createStore, {
  fetchData,
  getOutrightsUrl,
} from './core__eventsOutrights-store';
import EventsOutrightsReactApp from './component/core__eventsOutrightsReact';
import ScDecoder from 'Services/json/core__decoder';

import { TranslationsProvider } from 'Services/translations/core__translations';
import { checkEventsHeaderAppActive } from 'Services/global/core__entry-file-utils';
import { getCookie } from 'Services/core__services';

import { translationString } from './core__eventsOutrights-translations.js';

export const Translations = new TranslationsProvider('EventsOutrightsApp');

class EventsOutrightsApp {
  constructor(properties) {
    Object.assign(this, properties);
    if (this.nodeSSR) {
      Translations.get = properties.Translations.get.bind(
        properties.Translations
      );
      Translations.getAll = properties.Translations.getAll.bind(
        properties.Translations
      );
      return;
    }
    if (this.appConfig) {
      this.appConfig.eventsHeaderActive = checkEventsHeaderAppActive(
        this.appSection
      );
    }
    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }

  start(data, reduxSelector) {
    const ReactInstance = this.getReactApp(this.cookies);
    Translations.setAll(data);

    const jsx = (
      <ReduxProvider
        store={createStore(data || { data: { cookies: this.cookies } })}
      >
        <ReactInstance />
      </ReduxProvider>
    );
    ReactDOM[data ? 'hydrate' : 'render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }
  /**
   *
   * @param {Object} cookies, req.cookies object
   */
  getReactApp(cookies) {
    this.url = getOutrightsUrl(this.appConfig);
    const mapStateToProps = state => {
      const oddsFormat = cookies
        ? cookies['selectedOdds']
        : getCookie('selectedOdds');
      return {
        outrightsData: state.data.outrightsData,
        appConfig: state.data.appConfig || this.appConfig,
        url: this.url,
        oddsFormat: oddsFormat,
      };
    };
    const mapDispatchToProps = {
      url: this.url,
      fetchData,
    };

    EventsOutrightsReactApp.serverFetch = {
      url: this.url,
      fetchData,
      createStore,
      strToTranslate: translationString,
    };

    return connect(
      mapStateToProps,
      mapDispatchToProps
    )(EventsOutrightsReactApp);
  }
}

export default EventsOutrightsApp;
