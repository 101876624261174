import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';
import Constants from '../../../project';

const spin = keyframes`
100%{
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);  
}
`;
const SI = styled.img`
  -webkit-animation: ${spin} 4s linear infinite;
  -moz-animation: ${spin} 4s linear infinite;
  animation: ${spin} 4s linear infinite;
  display: ${({ d }) => (d ? 'block' : 'none')};
  width: ${({ w }) => `${w}px`};
  ${({ s }) => {
    return css`
      ${s}
    `;
  }}
`;
const SpinImg = `${Constants.staticPath.basePath}${
  Constants.staticPath.bucketReact
}${Constants.staticPath.folderIcon}${'spinner.svg'}`;

/**
 * Spinner Component
 * @param {bool} display  either true or false default value is false
 * @param {number} width width of image default is 24px
 * @param {style} style style is custom object user can user own Css just need to send in {}
 */

const Spinner = ({ display, width, style }) => {
  if (style && Object.keys(style).length > 0) {
    style = Object.entries(style)
      .map(([k, v]) => {
        return `${k}:${v}`;
      })
      .join(';');
  }

  return (
    <SI
      d={(display && display.toString()) || false}
      w={(width && width) || 24}
      s={(style && style) || null}
      src={SpinImg}
    />
  );
};

Spinner.propTypes = {
  display: PropTypes.bool,
  width: PropTypes.number,
  style: PropTypes.object,
};

export default Spinner;
