export const dataReducer = (state = {}, action) => {
  switch (action.type) {
    case 'EVENTS_OUTRIGHTS_DATA':
      return {
        ...state,
        outrightsData: action.payload,
        appConfig: action.appConfig,
      };
    case 'DATA_NOT_FOUND':
      return {
        ...state,
        statusCode: action.statusCode,
      };
    default:
      return state;
  }
};
