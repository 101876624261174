export const translationString = [
  'markets.viewmore',
  'markets.viewless',
  'number.nd',
  'number.st',
  'number.th',
  'number.rd',
  'month.jan',
  'month.feb',
  'month.mar',
  'month.apr',
  'month.may',
  'month.jun',
  'month.jul',
  'month.aug',
  'month.sep',
  'month.oct',
  'month.nov',
  'month.dec',
  'number.year',
];
