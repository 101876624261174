import { GREYS } from 'UI/globals/colours';
import styled, { css } from 'styled-components';
import { MarketRefLabel } from 'UI/apps/EventsApp/EventsTableBodySection';
import { ViewMoreBar } from 'UI/apps/EventsApp/EventsTableBody';

export const ButtonDiv = styled.div`
  width: 476px;
  display: flex;
  margin: 8px 16px 8px auto;
`;
export const TeamName = styled.div`
  line-height: 20px;
  font-size: 14px;
`;
export const Team = styled.div`
  margin: 22px auto 22px 15px;
  width: 100%;
`;
export const EventsRow = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${GREYS.silver};
  background-color: ${({ theme }) =>
    theme.dark ? GREYS.silverD : GREYS.white};
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};

  ${({ ew }) =>
    ew &&
    css`
      justify-content: flex-end;
      padding-bottom: 8px;
      padding-right: 12px;
    `}
`;
export const ModifiedViewMoreBar = styled(ViewMoreBar)`
  background-color: ${({ theme }) =>
    theme.dark ? GREYS.silverD : GREYS.white};
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  font-size: 12px;
  margin-bottom: 16px;
`;
export const MarketRefLabel_EX = styled(MarketRefLabel)`
  white-space: normal;
`;
export const EwTerms = styled.span`
  top: 4px;
`;
