export const outrightsData = data => {
  const appConfig = { ...data.appConfig };
  delete data['appConfig'];
  return { type: 'EVENTS_OUTRIGHTS_DATA', payload: data, appConfig };
};

export const updateBetslipsSelection = data => {
  const selectionId = data;
  if (selectionId) {
    return {
      type: 'CHANGE_ACTIVE_BETSLIP_SELECTION',
      data: selectionId,
    };
  } else {
    return { type: 'DO_NOTHING' };
  }
};

export const handleDataNotFound = statusCode => {
  return {
    type: 'DATA_NOT_FOUND',
    statusCode,
  };
};
