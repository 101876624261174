import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Kicker } from 'CORE__UI/apps/EventsOutrightsApp/core__Kickoff';
import PROJECT from '../../../../../../project';
import { getDate } from '../../../core__eventsOutrights-utils';
import { Constants } from '../../../core__eventsOutrights-constants';

export const Kickoff = ({ data, isShow }) => {
  const [date, setDate] = useState(getDate(data));

  useEffect(() => {
    if (isShow && data) {
      const updateDate = getDate(data);
      setDate(updateDate);
    }
  }, [data, isShow]);

  return (
    <>
      {isShow && data && (
        <Kicker data-test="component-kickoff">
          {PROJECT.JpTimeFormat
            ? date
            : moment(data).format(
                PROJECT.timeFormats.promotion.tertiary ||
                  Constants.DATE_TIME_FORMAT
              )}
        </Kicker>
      )}
    </>
  );
};
Kickoff.propTypes = {
  data: PropTypes.number,
  isShow: PropTypes.bool,
};
Kickoff.defaultProps = {
  data: null,
};
