import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  SOB,
  NAB,
  SS,
  OD,
  OU,
  CS,
  CSCnt,
  LS,
} from 'UI/buttons/OddsButtonStyles';
import { withTheme } from 'styled-components';
import project from '../../../../project';

const layerOuter = (label, layout) => {
  if (!label || label === '' || layout === 'handicap') {
    return CSCnt;
  } else {
    return CS;
  }
};

const styleAs = oddsTrend => {
  switch (oddsTrend) {
    case 'increase':
      return OU;
    case 'decrease':
      return OD;
    default:
      return SS;
  }
};

const OddsButton = ({
  size,
  label,
  odds,
  oddsTrend,
  layout,
  labelPos,
  selected,
  americanLayout,
  goalScorerLayout,
  ...props
}) => {
  const [oddsHighlight, setOddsHighlight] = useState(oddsTrend);
  let stringOdds = '';
  let oddsParts;
  if (odds) {
    stringOdds = odds.toString();
    if (stringOdds.includes('>>')) {
      oddsParts = stringOdds.split(' >> ');
    }
  } else {
    stringOdds = '';
  }
  let stringLabel = '';
  if (label) {
    if (goalScorerLayout) {
      stringLabel = label;
    } else if (label.length > 10 && project?.hideMarketName) {
      // This was done for Gamesys [GAMJP-163]
      label = label.substring(0, 9) + '...';
      stringLabel = label;
    }
  } else {
    stringLabel = '';
  }
  let ariaLabel;
  if (stringOdds && stringLabel) ariaLabel = stringLabel + ' ' + stringOdds;
  else if (stringOdds) ariaLabel = stringOdds;
  else if (stringLabel) ariaLabel = stringLabel;
  else ariaLabel = 'unavailable odds'; // to raise lighthouse accessibility score when there's no odds/label

  useEffect(() => {
    setOddsHighlight(oddsTrend);
    const timeout = setTimeout(() => setOddsHighlight(null), 1000);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [oddsTrend]);
  return (
    <React.Fragment>
      {odds ? (
        <React.Fragment>
          {label && project.hideMarketName && americanLayout && (
            <LS selected={selected} isMobile={true} labelPos={labelPos}>
              {label}
            </LS>
          )}
          <SOB
            size={size}
            selected={selected}
            labelPos={labelPos}
            aria-label={ariaLabel}
            {...props}
          >
            {oddsHighlight && <SS as={styleAs(oddsHighlight)}></SS>}
            <CS
              selected={selected}
              labelPos={labelPos}
              as={layerOuter(label, layout)}
            >
              {label && (
                <LS
                  selected={selected}
                  labels={project.hideMarketName && americanLayout}
                  labelPos={labelPos}
                >
                  {label}
                </LS>
              )}
              {stringOdds.includes('>>') ? (
                <span>
                  <s>{oddsParts[0]}</s> {' >> '} {oddsParts[1]}
                </span>
              ) : (
                <span>{odds}</span>
              )}
            </CS>
          </SOB>
        </React.Fragment>
      ) : (
        <NAB
          americanLayout={americanLayout}
          layout={layout}
          size={size}
          aria-label={ariaLabel}
          {...props}
        >{``}</NAB>
      )}
    </React.Fragment>
  );
};

OddsButton.propTypes = {
  /**
   * Just what it says on the tin.
   */
  odds: PropTypes.any,
  /**
   * Render odds trend
   */
  oddsTrend: PropTypes.oneOf(['increase', 'decrease', 'null']),
  /**
   * If it's disabled
   */
  inactive: PropTypes.bool,
  /**
   * If it's selected
   */
  selected: PropTypes.bool,
  /**
   * How tall should the button be?
   */
  size: PropTypes.oneOf(['small', 'default']),
  /**
   * "handicap" or not
   */
  layout: PropTypes.oneOf(['handicap', 'default']),
  /**
   * "murican" or not
   */
  americanLayout: PropTypes.bool,
  /**
   * For when addtional info is contained inside the button
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  /**
   * "up" or regular
   */
  labelPos: PropTypes.oneOf(['up', 'default']),
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * For when goalscorer market is available
   */
  goalScorerLayout: PropTypes.string,
};

OddsButton.defaultProps = {
  size: 'default',
  onClick: () => {},
  layout: 'default',
  labelPos: 'default',
  oddsTrend: 'null',
};

export default withTheme(OddsButton);
