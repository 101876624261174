export const Constants = {
  UpdateBetslipsSelection: 'updateBetslipsSelection',
  DEFAULT_COLUMNS: 2,
  DEFAULT_COLUMNS_AMERICAN: 3,
  MONEY_LINE: 'Money Line',
  SPREAD: 'Spread',
  TOTAL: 'Total',
  OVER: 'OVER',
  UNDER: 'UNDER',
  ODDS_INCREASE: 'increase',
  ODDS_DECREASE: 'decrease',
  DECIMAL: 'decimal',
  FRACTIONAL: 'fractional',
  maxRowsToShow: 10,
  DATE_TIME_FORMAT: 'D MMM HH:MM',
  JAPANESE: 'ja',
};
