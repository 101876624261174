import styled from 'styled-components';
import { FONT } from 'UI/globals/colours';

export const EAr = styled.span`
  cursor: pointer;
  transition: transform 250ms ease-out;
  transform: rotate(0deg);
  &:after {
    content: '';
    border-style: solid;
    border-width: 0 1px 1px 0;
    border-color: ${({ active }) =>
      active ? `${FONT.primary}` : `${FONT.dark}`};
    display: inline-block;
    padding: ${({ size }) => (size ? `${size}px` : `4.5px`)};
    transform: ${({ expanded }) =>
      expanded
        ? 'rotate(225deg)'
        : 'rotate(45deg)'}; // 45deg is chevron pointing down, 225 is pointing up
    position: relative;
    top: ${({ expanded, size }) =>
      `${(size / 2) * (expanded ? 1 : -1)}px`}; // move it by 1/2 of the size
    transition: all 0.2s ease-in-out 0s;
  }
`;
