import { EAr } from 'CORE__UI/globals/ExpandArrow/core__expandArrowStyles';
import styled from 'styled-components';
import { GREYS } from './colours';

export const EAR = styled(EAr)`
  &:after {
    border-color: ${GREYS.white};
  }
`;

export { EAR as EAr };
